import { click } from './tracking';

/**
 * Bind the click event on the given element
 *
 * @param {HTMLElement|Element} element Element to bind click event to
 */
export default function bindClickEvent(element) {
  // get tracking key and other infos
  const trackingKey = element.getAttribute('data-tracking');
  const trackingData = JSON.parse(element.getAttribute('data-trackdata'));
  let href = element.getAttribute(element.nodeName === 'A' ? 'href' : 'data-href');

  // send click tracking
  click(trackingKey, trackingData).then((response) => {
    // redirect after the click
    if (element.nodeName === 'A') {
      if (response.length) {
        href = response;
      }

      window.location.href = href;
    }
  });
}
